import React, { useId } from 'react';
import { Tooltip, Place } from '~/components/data-displays/Tooltip';
import QuestionIcon from '~/assets/icon/tooltip-question--gray2.svg';
import * as styles from './index.styles';

export type Props = {
  testId?: string;
  place?: Place;
  children: React.ReactNode;
  className?: string;
};

export const HintTooltip: React.FC<Props> = ({ testId, place, children, className }: Props) => {
  const tooltipId = useId();

  return (
    <>
      <span data-testid={testId}>
        <img src={QuestionIcon} alt="ヒント" data-tooltip-id={tooltipId} css={styles.icon} />
      </span>
      <Tooltip tooltipId={tooltipId} place={place} className={className}>
        {children}
      </Tooltip>
    </>
  );
};
